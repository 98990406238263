import React from "react";

import App from "./App";
import WalletConnect from "./WalletConnectWrapper";

//for NFT
import { configureChains, WagmiConfig, createConfig } from "wagmi";
import { polygon, polygonMumbai, localhost } from "wagmi/chains";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { publicProvider } from "wagmi/providers/public";
import NFT_Mint from "./NFT";

const chains = [polygonMumbai];

const {
  publicClient: wagmiPublicClient,
  webSocketPublicClient: wagmiWebSocketPublicClient,
} = configureChains(chains, [publicProvider()]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [new MetaMaskConnector({ chains })],
  publicClient: wagmiPublicClient,
  webSocketPublicClient: wagmiWebSocketPublicClient,
});

export default function AvatarAuthoringTool() {
  return (
    <>
      <App />

      <div className="hidden">
        <WagmiConfig config={wagmiConfig}>
          <WalletConnect />
        </WagmiConfig>
      </div>
    </>
  );
}
