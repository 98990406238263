import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useNetwork, useAccount, useDisconnect, useConnect } from "wagmi";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

import { useContractWrite, useWaitForTransaction } from "wagmi";
import publicNFTABI from "./publicNFT.json";

export default function WalletConnect({ children }) {
  const [openConnectorsDialog, setOpenConnectorsDialog] = useState(false);
  const [isWalletNetworkConnect, setIsWalletNetworkConnect] = useState();
  const { chains, chain } = useNetwork();
  const { disconnect } = useDisconnect();
  const { address, isConnected } = useAccount();
  const {
    connect,
    connectors,
    error: errorConnect,
    isLoading: isLoadingConnect,
    pendingConnector
  } = useConnect();

  useEffect(() => {
    console.log("call useEffect()");
    console.log("chain: ", chain);
    console.log("isConnected: ", isConnected);

    if (chain?.network === "maticmum") {
      setIsWalletNetworkConnect(true);
    } else {
      setIsWalletNetworkConnect(false);
    }
  }, [isConnected, chain]);

  const NFT_CONTRACT_ADDRESS = "0x17A274A6a95Ece95C99dc1f1dc439dD9AFba218a";
  const NFT_MINT_ADDRESS = "0x1e60Cf7B8fB0B7EaD221CF8D0e7d19c863FfbE40";

  const {
    data: dataSafeMint,
    error: errorSafeMint,
    isError: isErrorSafeMint,
    isIdle: isIdleSafeMint,
    isLoading: isLoadingSafeMint,
    isSuccess: isSuccessSafeMint,
    write: writeSafeMint,
    status: statusSafeMint
  } = useContractWrite({
    address: NFT_CONTRACT_ADDRESS,
    abi: publicNFTABI.abi,
    functionName: "safeMint",
    onSuccess(data) {
      // console.log("call onSuccess()");
      // console.log("data: ", data);
    },
    onError(error) {
      // console.log("call onError()");
      // console.log("error: ", error);
    },
    onSettled(data, error) {
      console.log("call onSettled()");
      console.log("data: ", data);
      console.log("error: ", error);
    }
  });

  const {
    data: dataSafeMintTx,
    isError: isErrorSafeMintTx,
    isLoading: isLoadingSafeMintTx
  } = useWaitForTransaction({
    hash: dataSafeMint?.hash,
    onSuccess(data) {
      // console.log("call onSuccess()");
      // console.log("data: ", data);
    },
    onError(error) {
      // console.log("call onError()");
      // console.log("error: ", error);
    },
    onSettled(data, error) {
      console.log("call onSettled()");
      console.log("data: ", data);
      console.log("error: ", error);
    }
  });


  function CheckingConnection() {
    return (
      <>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="0vh"
        >
          <Button id="Wallet_Connet"
            variant="contained"
            onClick={() => {
              setOpenConnectorsDialog(true);
            }}
          >
            Wallet CONNECT
          </Button>
        </Box>

        <Dialog
          onClose={() => setOpenConnectorsDialog(false)}
          open={openConnectorsDialog}
        >
          <DialogTitle>Select connectors</DialogTitle>
          <List sx={{ pt: 0 }}>
            {connectors.map((connector, idx) => (
              <ListItem disableGutters key={connector.id}>
                <ListItemButton
                  disabled={!connector.ready}
                  key={connector.id}
                  onClick={() => {
                    connect({ connector });
                    setOpenConnectorsDialog(false);
                  }}
                >
                  {connector.name}
                  {!connector.ready && " (unsupported)"}
                  {isLoadingConnect &&
                    connector.id === pendingConnector?.id &&
                    " (connecting)"}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Dialog>
      </>
    );
  }

  return (
    <>
       (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="0vh"
        >
          <Button id="Wallet_Connet"
            variant="contained"
            onClick={() => {
              setOpenConnectorsDialog(true);
            }}
          >
            Wallet CONNECT
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              disconnect();
            }}
          >
            Wallet DISCONNECT
          </Button>

          <Button id="NFT_Mint"
        onClick={() => {
          writeSafeMint?.({ args: [NFT_MINT_ADDRESS] });
        }}
      >
      </Button>

          {children}
        </Box>
      ) : (
        <CheckingConnection />
      )
    </>
  );
}
