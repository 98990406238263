import { Unity, useUnityContext } from "react-unity-webgl";
import React, { useState, useCallback } from "react";

import UnityInstance from "react-unity-webgl";

let isInitialLoad = true;
const RESOURCE_URI = process.env.REACT_APP_RESOURCE_URI;
const API_URI = process.env.REACT_APP_API_URI;
let tapList = [];
let itemList = [];

function App() {
  const [value, setValue] = useState("");
  const onChange = (e) => {
    setValue(e.target.value);
    sendMessage("WebToUnity", "TransferTextToUnity", e.target.value); //call public void TranserTextToUnity(string str: e.target.value) fuction in "WebToUnity" Game Object.
  };
  const onFocus = () => {
    sendMessage("WebToUnity", "SetUnityFocus", 0); //Deactive Unity key inputs
  };

  const onBlur = () => {
    sendMessage("WebToUnity", "SetUnityFocus", 1); //Active Unity key inputs
  };
  function getIcon(lable /*string*/, name /*string*/) {}

  const baseUrl = "https://public.dev.dxr.kr/UnityBuild";
  const { unityProvider, isLoaded, loadingProgression, sendMessage } =
    useUnityContext({
      loaderUrl: `${baseUrl}/Avatar_Authoring_Tool_Web.loader.js`,
      dataUrl: `${baseUrl}/Avatar_Authoring_Tool_Web.data`,
      frameworkUrl: `${baseUrl}/Avatar_Authoring_Tool_Web.framework.js`,
      codeUrl: `${baseUrl}/Avatar_Authoring_Tool_Web.wasm`,
    });

  function Load(Lable, Name) {
    const jsonTemp = {
      Type: "model",
      Lable: Lable,
      Name: Name,
    };
    console.log(JSON.stringify(jsonTemp));
    sendMessage("SpaceLoader", "LoadResource", JSON.stringify(jsonTemp));
  }
  isInitialLoad = false;

  return (
    <main className="flex w-screen h-screen">
      <Unity
        unityProvider={unityProvider}
        className={isLoaded ? "grow overflow-hidden bg-blue" : "w-0 h-0"}
        style={{ visibility: isLoaded ? "visible" : "hidden" }}
      />
      {!isLoaded ? (
        <p className="w-full text-5xl text-center text-white">
          Loading Unity Applicatation... {Math.round(loadingProgression * 100)}%
        </p>
      ) : (
        <div className="w-0 h-0"></div>
      )}
    </main>
  );
}

export default App;
