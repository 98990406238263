import React from "react";
import "./App.sass";

const MenuData = [
  {
    label: "아바타",
    cls: "avatar",
    //url: "http://125.131.233.151:7777",
    url: "/avatar",
    description: "아바타 저작도구를 사용하여\n나만의 아바타를 꾸며 보세요!",
  },
  {
    label: "공간",
    cls: "room",
    url: "http://125.131.233.151:7201",
    description: "공간 저작도구를 사용하여\n나만의 공간을 꾸며 보세요!",
  },
  {
    label: "웹툰",
    cls: "webtoon",
    url: "",
    description: "웹툰 저작도구를 사용하여\n나만의 웹툰을 꾸며 보세요!",
  },
];

export default function App() {
  const [sel, setSel] = React.useState(-1);

  return (
    <div id="base">
      <header>
        {MenuData.map((mnu, i) => (
          <button
            key={i}
            className={`${mnu.cls} ${sel === i ? "sel" : "ns"}`}
            onClick={() => setSel(i)}
          >
            {mnu.label}
          </button>
        ))}
      </header>

      {/* 메뉴 선택 */}
      {sel === -1 && (
        <div className="main-start">
          <div className="main-start-inner">
            <p className="main-start-title">스스로 만드는 다양한 저작도구!</p>
            <div className="main-start-cards">
              {MenuData.map((mnu, i) => (
                <div
                  className={`main-start-card main-start-card-${i}`}
                  key={i}
                  onClick={() => setSel(i)}
                >
                  <div className="main-start-card-inner">
                    <p className="main-start-card-title">{mnu.label} 만들기</p>
                    <p className="main-start-card-description">
                      {mnu.description}
                    </p>
                    <div className="main-start-card-image"></div>
                  </div>
                </div>
              ))}
            </div>
            <button className="main-start-next"></button>
          </div>
        </div>
      )}

      {MenuData.map((mnu, i) => (
        <main
          key={mnu.cls}
          className={`base ${mnu.cls} ${sel === i ? "sel" : "ns"}`}
        >
          <iframe
            title={mnu.label}
            name={mnu.cls}
            src={mnu.url}
            loading="lazy"
          ></iframe>
        </main>
      ))}
    </div>
  );
}
